<template>
  <b-card title="User Package Change History" v-if="userData">
    <app-timeline class="scrollable scrollable_group pl-1">
      <!-- 12 INVOICES HAVE BEEN PAID -->
      <app-timeline-item v-for="(item, index) in userData" :key="index">
        <div
          class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0"
        ></div>
        <p class="mb-0">
          <strong>Current Package: </strong> {{ item.package.name }} |
          <strong>Price : </strong> {{ item.package_price }} Tk
        </p>
        <p class="mb-0" v-if="item.discount">
          <strong>Discount : </strong> {{ item.discount }} Tk
        </p>
        <p class="mb-0">
          <strong>Previous Package: </strong> {{ item.oldpackage.name }} |
          <strong>Price : </strong> {{ item.oldpackage.price }} Tk
        </p>
        <p class="m-0">
          <small class="text-muted"
            >Changed Date:{{ formatDateTime(item.created_at) }}</small
          >
        </p>
      </app-timeline-item>
    </app-timeline>
  </b-card>
</template>

<script>
import { BCard, BImg, BMedia, BAvatar } from "bootstrap-vue";
import AppTimeline from "@core/components/app-timeline/AppTimeline.vue";
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue";
import Ripple from "vue-ripple-directive";
import axios from "axios";
import Swal from "sweetalert2";
export default {
  name: "UserViewUserPackageChangeHistory",
  data() {
    return {};
  },
  methods: {},

  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  directives: {
    Ripple,
  },
  components: {
    BCard,
    BImg,
    BMedia,
    BAvatar,
    AppTimeline,
    AppTimelineItem,
  },
};
</script>

<style></style>
