<template>
  <b-card
    :title="`User Balance History (${userData.customer_balance_history.length})`"
    v-if="userData"
  >
    <app-timeline class="scrollable scrollable_group pl-1">
      <!-- 12 INVOICES HAVE BEEN PAID -->
      <!-- {{ userData.ticket[0] }} -->
      <Table>
        <thead>
          <tr>
            <th>#</th>
            <th>Date</th>
            <th>Balance</th>
            <th>Reasons</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody class="table table-hover">
          <tr
            class="text-center"
            v-for="(item, index) in userData.customer_balance_history"
            :key="index"
          >
            <td>{{ index + 1 }}</td>
            <td>
              <span class="badge text-secondary">{{
                formatDateTime(item.created_at)
              }}</span>
            </td>
            <td>{{ item.balance }} Tk</td>
            <td>{{ item.update_Reasons }}</td>
            <td>{{ item.status }}</td>
          </tr>
        </tbody>
      </Table>
    </app-timeline>
  </b-card>
</template>

<script>
import { BCard, BImg, BMedia, BAvatar } from "bootstrap-vue";
import AppTimeline from "@core/components/app-timeline/AppTimeline.vue";
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue";
import Ripple from "vue-ripple-directive";
import axios from "axios";
import Swal from "sweetalert2";
export default {
  data() {
    return {};
  },
  methods: {
    async refund(id) {
      this.$store.dispatch("spinnerLoading", false);
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to refund this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#70e111",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      }).then((result) => {
        if (result.isConfirmed) {
          axios.get(`invoice/refund/${id}`).then((resp) => {
            this.$store.dispatch("spinnerLoading", false);
            if (resp.data.success) {
              this.$s_tost(resp.data.message);
              window.location.reload();
            } else {
              this.$error_message(resp.data.message, resp.data.errors);
            }
          });
        }
      });
      // const deldata = await this.$d_conf(`invoice/refund/${id}`)
      // console.log(deldata);
    },
  },

  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  directives: {
    Ripple,
  },
  components: {
    BCard,
    BImg,
    BMedia,
    BAvatar,
    AppTimeline,
    AppTimelineItem,
  },
};
</script>

<style></style>
