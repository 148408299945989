var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.userData)?_c('b-card',{attrs:{"title":("User Ticket (" + (_vm.userData.ticket.length) + ")")}},[_c('app-timeline',{staticClass:"scrollable scrollable_group pl-1"},_vm._l((_vm.userData.ticket),function(item,index){return _c('app-timeline-item',{key:index},[_c('div',{staticClass:"d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0"},[_c('h6',[_vm._v(_vm._s(item.details))]),_vm._v(" "),_c('br')]),_vm._v(" "),_c('p',{staticClass:"m-0"},[_c('small',{staticClass:"text-muted"},[_vm._v("Create Date:"+_vm._s(_vm.formatDateInAgo(item.created_at))+" |")]),_vm._v(" "),_c('small',{staticClass:"text-muted"},[_vm._v("\n          Last Update:"+_vm._s(_vm.formatDateInAgo(item.updated_at)))])]),_vm._v(" "),_c('p',{staticClass:"mb-0"},[_c('span',{staticClass:"align-bottom"},[_vm._v("Status :\n          "),_c('b-badge',{staticClass:"text-capitalize",attrs:{"pill":"","variant":("" + (item.status == 0
                ? 'warning'
                : item.status == 1
                ? 'info'
                : item.status == 2
                ? 'success'
                : ''))}},[_vm._v("\n            "+_vm._s(item.status == 0
                ? "Pending"
                : item.status == 1
                ? "Processing"
                : item.status == 2
                ? "Complete"
                : "")+"\n          ")])],1),_vm._v(" "),_c('span',[_vm._v("Priority: "+_vm._s(item.priority))])])])}),1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }