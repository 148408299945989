<template>
  <div>
    <b-card no-body>
      <h4 class="action_title">Session History</h4>
      <b-card-body class="p-0 px-2 pb-1">
        <b-row>
          <b-button class="mr-1 float-left text-capitalize"
            @click.prevent="$refs.sessionhistorymodal.sessionActionData(userData)" variant="outline-success" size="sm">
            User Session History
          </b-button>
          <UserSessionHistoryModel ref="sessionhistorymodal" />
        </b-row>
      </b-card-body>
    </b-card>
  </div>
</template>
<script>
import { BCard, BButton, BAvatar, BRow, BCol, BBadge, BCardBody } from "bootstrap-vue";
// import useUsersList from "../users-list/useUsersList";
import UserSessionHistoryModel from "../components/UserSessionHistoryModel.vue";

import Swal from "sweetalert2";
export default {
  name: "ViewUsersSessionHistory",
  components: {
    BCard,
    BButton,
    BRow,
    BCol,
    BAvatar,
    BBadge,
    BCardBody,
    UserSessionHistoryModel,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  setup() {
    // const { resolveUserRoleVariant } = useUsersList();
    return {
    };
  },
  methods: {

  }
};
</script>

<style>
.action_title {
  padding: 5px;
}

.bage_083dff {
  background: #083dff;
}
</style>
