<template>
    <b-modal id="user-session-history-modal" :title="modal_title" size="xl" hide-footer>
        <b-row class="match-height">
            <b-col lg="3" sm="3">
                <div class="d-flex">
                    <flat-pickr v-model="date_range" class="form-control" :config="{
                        mode: 'range',
                    }" placeholder="Session Date" @on-close="filterChange" />
                    <input type="button" @click.prevent="reset()" value="Reset" class="w-25 form-control" />
                </div>
            </b-col>
            <b-col lg="3" sm="3">
                <table>
                    <tr v-if="spletData(date_range, ' to')[0]">
                        <td>From Time</td>
                        <td>: {{ spletData(date_range, ' to')[0] }}</td>
                    </tr>
                    <tr v-else>
                        <td></td>
                    </tr>
                    <tr>
                        <td>Upload Data</td>
                        <td>: {{ calculateBandwidth(totalData('upload')) }}</td>
                    </tr>

                    <tr>
                        <td>Total Data</td>
                        <td>: {{ calculateBandwidth(totalData('total')) }}</td>
                    </tr>
                </table>
            </b-col>
            <b-col lg="3" sm="3">
                <table>
                    <tr v-if="spletData(date_range, ' to')[1]">
                        <td>To Time</td>
                        <td>: {{ spletData(date_range, ' to')[1] }}</td>
                    </tr>
                    <tr v-else>
                        <td></td>
                    </tr>

                    <tr>
                        <td>Download Data</td>
                        <td>: {{ calculateBandwidth(totalData('download')) }}</td>
                    </tr>

                </table>
            </b-col>
        </b-row>
        <b-row class="match-height">
            <b-col lg="3" sm="3">
            </b-col>
        </b-row>
        <table v-if="listModalData.length > 0" class="table table-sm table-striped hover mt-2">
            <table>
                <thead>
                    <tr>
                        <th>User Name</th>
                        <th>Login Mac Address</th>
                        <th>Start Time</th>
                        <th>End Time</th>
                        <th>Total Online Time</th>
                        <th>Package Name</th>
                        <th>Download Data</th>
                        <th>Upload Data</th>
                        <th>Total Data</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, index) in listModalData" :key="index + 'session'">
                        <td>{{ item.username }}</td>
                        <td>{{ item.callingstationid }}</td>
                        <td> <span class="badge text-secondary">{{ item.acctstarttime }}</span></td>
                        <td> <span :class="`badge ${item.acctstoptime ? 'text-secondary' : 'text-success'}`">{{
                            item.acctstoptime ? item.acctstoptime : 'Online'
                        }}</span>
                        </td>
                        <td> <span class="badge text-success">{{ formatSeconds(item.acctsessiontime) }}</span></td>
                        <td>{{ item.package_id }}</td>
                        <td>{{ calculateBandwidth(item.acctinputoctets) }}</td>
                        <td>{{ calculateBandwidth(item.acctoutputoctets) }}</td>
                        <td>{{ calculateBandwidth(item.acctinputoctets + item.acctoutputoctets) }}</td>
                    </tr>
                </tbody>
            </table>
        </table>
        <button @click="close" class="btn btn-warning mt-1">Close</button>
    </b-modal>
</template>

<script>
import axios from 'axios';
import StatisticCardHorizontal from "@core/components/statistics-cards/StatisticCardHorizontal.vue";
import flatPickr from "vue-flatpickr-component";
import Ripple from "vue-ripple-directive";
import Swal from 'sweetalert2';
export default {
    components: {
        StatisticCardHorizontal,
        flatPickr,
        Ripple,
        data: null
    },
    data() {
        return {
            user_id: null,
            modal_title: "User Session History",
            date_range: "",
            listModalData: []
        }
    },
    
    methods: {
        totalData(action) {
            if (action == 'upload') {
                return this.listModalData.reduce((acc, item) => acc + item.acctoutputoctets, 0);
            } else if (action == 'download') {
                return this.listModalData.reduce((acc, item) => acc + item.acctinputoctets, 0);
            } else if (action == 'total') {
                return this.listModalData.reduce((acc, item) => acc + (item.acctinputoctets + item.acctoutputoctets), 0);
            }
        },
        filterChange() {
            setTimeout(() => {
                this.sessionActionData(this.data);
            }, 100);
        },
        formatSeconds(value) {
            const year = Math.floor(value / 31536000);
            value -= year * 31536000;
            const month = Math.floor(value / 2592000);
            value -= month * 2592000;
            const week = Math.floor(value / 604800);
            value -= week * 604800;
            const day = Math.floor(value / 86400);
            value -= day * 86400;
            const hour = Math.floor(value / 3600);
            value -= hour * 3600;
            const minute = Math.floor(value / 60);
            const second = value % 60;
            let result = '';
            if (year > 0) result += year + ' Y ';
            if (month > 0) result += month + ' M ';
            if (week > 0) result += week + ' W ';
            if (day > 0) result += day + ' D ';
            if (hour > 0) result += hour + ' H ';
            if (minute > 0) result += minute + ' M ';
            if (second > 0) result += second + ' S ';
            return result.trim();
        },

        calculateBandwidth(bytes) {
            // return (bytes / (1024 * 1024 * 1024)).toFixed(2) + " GB";
            if (bytes < 1024 * 1024) {
                // less than 1 MB
                return (bytes / 1024).toFixed(2) + " KB";
            } else if (bytes < 1024 * 1024 * 1024) {
                // between 1 MB and 1 GB
                return (bytes / (1024 * 1024)).toFixed(2) + " MB";
            } else {
                // 1 GB or greater
                return (bytes / (1024 * 1024 * 1024)).toFixed(2) + " GB";
            }
        },
        sessionActionData(data) {
            this.data = data;
            if (data.connection_info.username !== null) {
                this.$store.dispatch("spinnerLoading", true);
                // this.axios.get(`user-session-history?username=${data.connection_info.username}&daterange=${this.date_range}`).then((resp) => {
                this.axios.get(`user-session-history?username=test&daterange=${this.date_range}`).then((resp) => {
                    if (resp.data.success) {
                        this.listModalData = resp.data.data;
                        this.$bvModal.show('user-session-history-modal')
                    } else {
                    }
                }).finally(() => {
                    this.$store.dispatch("spinnerLoading", false);
                })
            } else {
                return $error_message('data not set')
            }
        },
        // close modal 
        close() {
            this.listModalData = [];
            this.date_range = ""
            this.$bvModal.hide("user-session-history-modal");
        },
        reset() {
            this.date_range = ''
            this.sessionActionData(this.data);

        },
    }
}
</script>