<template>
  <div>
    <template v-if="userData !== null">
      <!-- First Row -->
      <b-row>
        <b-col cols="12" xl="9" lg="8" md="7">
          <user-view-user-info-card :user-data="userData" />
        </b-col>
        <b-col cols="12" md="5" xl="3" lg="4">
          <user-view-user-plan-card :user-data="userData" />
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12" md="5" xl="5" lg="5">
          <UsersActions :user-data="userData" />
        </b-col>
        <b-col cols="12" md="4" xl="4" lg="4">
          <UserCountdownCard :user-data="userData" />
        </b-col>
        <b-col cols="12" md="3" xl="3" lg="3">
          <ViewUsersSessionHistory :user-data="userData" />
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" lg="6">
          <user-view-user-timeline-card :user-data="userData" />
        </b-col>
        <b-col cols="12" lg="6">
          <user-view-user-permissions-card :user-data="userData" />
        </b-col>
      </b-row>

      <!-- user package chage history -->
      <b-row>
        <b-col v-if="user_packga_change_history.length" cols="12" lg="6">
          <user-view-user-package-change-history :user-data="user_packga_change_history" />
        </b-col>
      </b-row>
      <!-- user package chage history  end-->
      <b-row>
        <b-col v-if="userData.ticket.length" cols="12" lg="6">
          <user-view-user-ticket-card :user-data="userData" />
        </b-col>
        <b-col v-if="userData.customer_balance_history.length" cols="12" lg="6">
          <user-view-user-balance-history-card :user-data="userData" />
        </b-col>
      </b-row>
    </template>
  </div>
</template>

<script>
import { BRow, BCol, BAlert, BLink } from "bootstrap-vue";
import UserViewUserInfoCard from "./UserViewUserInfoCard.vue";
import UserViewUserPlanCard from "./UserViewUserPlanCard.vue";
import UserViewUserTimelineCard from "./UserViewUserTimelineCard.vue";
import UserViewUserPackageChangeHistory from "./UserViewUserPackageChangeHistory.vue";
import UserViewUserPermissionsCard from "./UserViewUserPermissionsCard.vue";
import ViewUsersSessionHistory from "./ViewUsersSessionHistory.vue";
import UserCountdownCard from "./UserCountdownCard.vue";
import UsersActions from "./UsersActions.vue";
import UserViewUserTicketCard from "./UserViewUserTicketCard.vue";
import UserViewUserBalanceHistoryCard from "./UserViewUserBalanceHistoryCard.vue";
import axios from "axios";

export default {
  data() {
    return {
      // userData: null
      user_packga_change_history: {},
    };
  },
  components: {
    BRow,
    BCol,
    BAlert,
    BLink,
    // Local Components
    UsersActions,
    UserCountdownCard,
    ViewUsersSessionHistory,
    UserViewUserInfoCard,
    UserViewUserPlanCard,
    UserViewUserTimelineCard,
    UserViewUserPermissionsCard,
    UserViewUserTicketCard,
    UserViewUserBalanceHistoryCard,
    UserViewUserPackageChangeHistory,
  },

  computed: {
    userData() {
      return this.$store.state.isp.customer_data;
    },
  },
  created() {
    this.$store.dispatch("customer_data_action", this.$route.params.id);
    this.get_user_pack_change_history(this.$route.params.id);
  },
  methods: {
    get_user_pack_change_history($id) {
      axios.get(`user-package-change-history/${$id}`).then((resp) => {
        this.$store.dispatch("spinnerLoading", false);
        if (resp.data.success) {
          this.user_packga_change_history = resp.data.data;
        } else {
          this.$error_message(resp.data.message, resp.data.errors);
        }
      });
    },
  },
};
</script>

<style></style>
