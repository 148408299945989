<template>
  <b-card no-body>
    <b-card-body>
      <b-card-title>Uer Details</b-card-title>
      <table>
        <tbody>
          <tr>
            <th> <Strong>Name</Strong></th>
            <th> <Strong>:</Strong></th>
            <td> {{ userData.name }}</td>
          </tr>
          <tr>
            <th> <Strong>Fathers Name</Strong></th>
            <th> <Strong>:</Strong></th>
            <td> {{ userData.userdetails.father_name }}</td>
          </tr>
          <tr>
            <th> <Strong>Mothers Name</Strong></th>
            <th> <Strong>:</Strong></th>
            <td> {{ userData.userdetails.mother_name }}</td>
          </tr>
        </tbody>
      </table>
     
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BTable,
  BCardBody,
  BCardTitle,
  BCardSubTitle,
  BFormCheckbox,
} from "bootstrap-vue";

export default {
  components: {
    BCard,
    BTable,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BFormCheckbox,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  setup() {
    return {};
  },
};
</script>

<style></style>
