<template>
  <b-card
    :title="`User Ticket (${userData.ticket.length})`"
    v-if="userData"
  >
    <app-timeline class="scrollable scrollable_group pl-1">
      <!-- 12 INVOICES HAVE BEEN PAID -->
      <!-- {{ userData.ticket[0] }} -->
      <app-timeline-item v-for="(item, index) in userData.ticket" :key="index">
        <div
          class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0"
        >
          <h6>{{ item.details }}</h6>
          <br />
          <!-- <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
            <template v-slot:button-content>
              <feather-icon icon="MoreVerticalIcon" size="16" class="text-body align-middle mr-25" />
            </template>
            <b-dropdown-item @click.prevent="refund(item.id)">
              <feather-icon icon="DollarSignIcon" class="mr-50" />
              <span>Refund Invoice</span>
            </b-dropdown-item>
          </b-dropdown> -->
        </div>
        <p class="m-0">
          <small class="text-muted"
            >Create Date:{{ formatDateInAgo(item.created_at) }} |</small
          >
          <small class="text-muted">
            Last Update:{{ formatDateInAgo(item.updated_at) }}</small
          >
        </p>
        <p class="mb-0">
          <span class="align-bottom"
            >Status :
            <b-badge
              pill
              class="text-capitalize"
              :variant="`${
                item.status == 0
                  ? 'warning'
                  : item.status == 1
                  ? 'info'
                  : item.status == 2
                  ? 'success'
                  : ''
              }`"
            >
              {{
                item.status == 0
                  ? "Pending"
                  : item.status == 1
                  ? "Processing"
                  : item.status == 2
                  ? "Complete"
                  : ""
              }}
            </b-badge>
          </span>
          <span>Priority: {{ item.priority }}</span>
        </p>
      </app-timeline-item>
    </app-timeline>
  </b-card>
</template>

<script>
import { BCard, BImg, BMedia, BAvatar } from "bootstrap-vue";
import AppTimeline from "@core/components/app-timeline/AppTimeline.vue";
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue";
import Ripple from "vue-ripple-directive";
import axios from "axios";
import Swal from "sweetalert2";
export default {
  data() {
    return {};
  },
  methods: {
    async refund(id) {
      this.$store.dispatch("spinnerLoading", false);
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to refund this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#70e111",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      }).then((result) => {
        if (result.isConfirmed) {
          axios.get(`invoice/refund/${id}`).then((resp) => {
            this.$store.dispatch("spinnerLoading", false);
            if (resp.data.success) {
              this.$s_tost(resp.data.message);
              window.location.reload();
            } else {
              this.$error_message(resp.data.message, resp.data.errors);
            }
          });
        }
      });
      // const deldata = await this.$d_conf(`invoice/refund/${id}`)
      // console.log(deldata);
    },
  },

  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  directives: {
    Ripple,
  },
  components: {
    BCard,
    BImg,
    BMedia,
    BAvatar,
    AppTimeline,
    AppTimelineItem,
  },
};
</script>

<style></style>
