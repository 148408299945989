<template>
  <b-card no-body title="Actions">
    <h4 class="action_title">Actions</h4>
    <b-card-body class="p-0 px-2 pb-1">
      <b-row>
        <b-button @click.prevent="$refs.remotAddressModel.see_remote_address(userData.id)" class="mr-1 float-left"
          variant="outline-primary" size="sm">
          Assign Static IP
        </b-button>
        <RemoteAddressModel ref="remotAddressModel" />
        <b-button class="mr-1 float-left" v-if="userData.connection_info.nas_id" @click.prevent="
          desabled_user(userData)"
          :variant="userData.connection_info.status == 0 ? 'outline-warning' : 'outline-danger'" size="sm">
          Disabled
        </b-button>
        <b-button class="mr-1 float-left mt-1" @click="
          checkOnlineStatus(userData)
        " variant="outline-info" size="sm">
          Check Connection Status
        </b-button>
        <b-button class="mr-1 float-left mt-1 text-capitalize" @click="clean_stale_session(userData)"
          variant="outline-success" size="sm">
          clean Stale session
        </b-button>
      </b-row>
    </b-card-body>
  </b-card>
</template>
<script>
import { BCard, BButton, BAvatar, BRow, BCol, BBadge, BCardBody } from "bootstrap-vue";
// import useUsersList from "../users-list/useUsersList";
import RemoteAddressModel from "../components/RemoteAddressModel.vue";
import Swal from "sweetalert2";
import { now } from "moment";
export default {
  name: "UsersActions",
  components: {
    BCard,
    BButton,
    BRow,
    BCol,
    BAvatar,
    BBadge,
    BCardBody,
    RemoteAddressModel,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  setup() {
    // const { resolveUserRoleVariant } = useUsersList();
    return {
    };
  },
  methods: {
    //checkOnlineStatus
    async checkOnlineStatus(data) {
      if (this.$store.state.isp.system_nas_type == "MikroTik") {
        const username = data.connection_info.username;
        const mikrotik_id = data.connection_info.mikrotik_id;
        // if nas type is mikrotik
        let route = `/mikrotik-api-request?mikrotik=${mikrotik_id}&username=${username}&type=get_online_data`;
        await this.axios.get(route).then((resp) => {
          if (resp.data.success) {
            const user_data = resp.data.data[0];
            Swal.fire({
              title: "<strong>Online Status</strong>",
              html:
                `<strong>Name: </strong> ${username} </br>` +
                `<strong v-if="${mikrotik_id == 5}"> Address: </strong>${user_data.address
                } </br>` +
                `<strong>caller-id: ${user_data["caller-id"]} </strong> </br>` +
                `<strong>Up Time: ${user_data.uptime} </strong>`,
              showCloseButton: false,
              showCancelButton: true,
              focusConfirm: false,
              confirmButtonText: "Disconnect User",
              confirmButtonColor: "red",
            }).then((result) => {
              const route2 = `/mikrotik-api-request?mikrotik=${mikrotik_id}&username=${username}&type=disconnect`;
              if (result.isConfirmed) {
                this.axios.get(route2).then((resp) => {
                  if (resp.data.success) {
                    this.$s_tost(resp.data.message);
                  } else {
                    this.$error_message(resp.data.messadge, resp.data.errors);
                  }
                });
              }
            });
          } else {
            this.$error_message(resp.data.messadge, resp.data.errors);
          }
        });
      } else {
        // if nas type is Radius and MikroTik+Radius
        await this.axios.get(`check-radius-online-status?username=${data.connection_info.username}`).then((resp) => {
          if (resp.data.success) {
            const date = new Date(resp.data.data.acctstoptime !== null ? resp.data.data.acctstoptime : resp.data.data.acctstarttime);
            const now = new Date();
            const diffMs = date - now;
            const diffSec = Math.abs(Math.floor(diffMs / 1000));
            const diffMin = Math.abs(Math.floor(diffSec / 60));
            const diffHrs = Math.abs(Math.floor(diffMin / 60));
            const diffDays = Math.abs(Math.floor(diffHrs / 24));
            let active_time = '';
            if (diffDays > 0) active_time = `${diffDays} days,`;
            if (diffHrs % 24 > 0) active_time += ` ${diffHrs % 24} hours,`;
            if (diffMin % 60 > 0) active_time += ` ${diffMin % 60} minutes,`;
            if (diffSec % 60 > 0) active_time += ` ${diffSec % 60} seconds`;
            active_time += `${resp.data.data.acctstoptime == null ? '' : ' ago'}`;
            Swal.fire({
              title: `<strong>Online Status <span class='badge badge-info'>${resp.data.data.acctstoptime == null ? 'Connected' : "Not Connected"}</span></strong>`,
              html:
                `<strong>${resp.data.data.acctstoptime !== null ? 'Last Active' : 'Active On'} : ${active_time} </strong> </br>`,
              showCloseButton: true,
              showCancelButton: false,
              focusConfirm: false,
              confirmButtonText: `${resp.data.data.acctstoptime == null ? 'Disconnect User' : 'Ok'}`,
              confirmButtonColor: `${resp.data.data.acctstoptime == null ? 'red' : "green"}`,
            }).then((result) => {
              if (result.isConfirmed && resp.data.data.acctstoptime == null) {
                Swal.fire({
                  title: "Are you sure?",
                  text: `You Want to Disconnect This User`,
                  icon: "warning",
                  showCancelButton: true,
                  confirmButtonColor: "#70e111",
                  cancelButtonColor: "#d33",
                  confirmButtonText: "Yes!",
                }).then((result) => {
                  if (result.isConfirmed) {
                    this.axios.post(`disconnect-radius-user?`, data).then((resp) => {
                      if (resp.data.success) {
                        this.$s_tost(resp.data.message);
                      } else {
                        this.$error_message(resp.data.messadge, resp.data.errors);
                      }
                    });
                  }
                })
              }
            });
          }
        });
      }
    },
    // desabled_user Toggle buttom
    async desabled_user(data) {
      await Swal.fire({
        title: "Are you sure?",
        text: `You want to Change It`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#70e111",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      }).then((result) => {
        if (result.isConfirmed) {
          this.axios.post(`disabled-user?nas_type=${this.$store.state.isp.system_nas_type}`, data).then((resp) => {
            if (resp.data.success) {
              this.userData.connection_info.status = resp.data.data == 'enabled' ? 0 : 1
              this.$s_tost(resp.data.message);
            } else {
              this.$error_message(resp.data.messadge, resp.data.errors);
            }
          });
        }
      });
    },


    // clean stale session
    async clean_stale_session(data) {
      await Swal.fire({
        title: "Are you sure?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#70e111",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      }).then((result) => {
        if (result.isConfirmed) {
          this.axios.get(`clean-stale-session?username=${data.connection_info.username}`, data).then((resp) => {
            if (resp.data.success) {
              this.$s_tost(resp.data.message);
            } else {
              this.$error_message(resp.data.messadge, resp.data.errors);
            }
          })
        }
      });
    }
  }
};
</script>

<style>
.action_title {
  padding: 5px;
}

.bage_083dff {
  background: #083dff;
}
</style>
