<template>
  <div>
    <h4 class="text-capitalize">User expiring in:</h4>
    <div class="badge badge-light-primary" rounded>{{ days }} Days</div>
    <div class="badge badge-light-primary" rounded>{{ hours }} Hours</div>
    <div class="badge badge-light-primary" rounded>{{ minutes }} Minutes</div>
    <div class="badge badge-light-primary" rounded>{{ seconds }} Seconds</div>
  </div>
</template>

<script>
export default {
  props: {
    targetDate: {
      required: true
    }
  },
  data() {
    return {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
      intervalId: null
    }
  },
  mounted() {
    this.intervalId = setInterval(() => {
      const now = new Date();
      const diff = Math.max(0, this.targetDate - now); // Make sure the countdown doesn't go negative
      this.days = Math.floor(diff / (1000 * 60 * 60 * 24));
      this.hours = Math.floor((diff / (1000 * 60 * 60)) % 24);
      this.minutes = Math.floor((diff / (1000 * 60)) % 60);
      this.seconds = Math.floor((diff / 1000) % 60);
    }, 1000);
  },
  beforeUnmount() {
    clearInterval(this.intervalId); // Clear the interval when the component is unmounted
  }
};
</script>
