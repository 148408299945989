<template>
  <b-card title="User Invoices" v-if="userData">
    <app-timeline class="scrollable scrollable_group pl-1">
      <!-- 12 INVOICES HAVE BEEN PAID -->
      <app-timeline-item v-for="(item, index) in userData.invoice" :key="index">
        <div
          class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0"
        >
          <h6>{{ item.invoice_no }}</h6>
          <br />
          <b-dropdown
            variant="link"
            toggle-class="text-decoration-none"
            no-caret
          >
            <template v-slot:button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="text-body align-middle mr-25"
              />
            </template>
            <b-dropdown-item @click.prevent="refund(item.id)">
              <feather-icon icon="DollarSignIcon" class="mr-50" />
              <span>Refund Invoice</span>
            </b-dropdown-item>
            <b-dropdown-item v-if="item.status == 'Pending'" @click="makePayment(item.id)">
              <feather-icon icon="DollarSignIcon" class="mr-50" />
              <span>Make Payment</span>
            </b-dropdown-item>
          </b-dropdown>
        </div>
        <p class="mb-0">
          <strong>Amount : </strong> {{ item.amount }} Tk |
          <strong>Payment : </strong> {{ item.received_amount }} Tk
        </p>
        <p class="m-0">
          <small class="text-muted"
            >Create Date:{{ formatDateInAgo(item.created_at) }} |</small
          >
          <small class="text-muted">
            Last Update:{{ formatDateInAgo(item.updated_at) }}</small
          >
        </p>
        <!-- {{ item }} -->
        <!-- <b-img :src="require('@/assets/images/icons/pdf.png')" height="auto" width="20" class="mr-1" /> -->
        <p class="mb-0">
          <span class="align-bottom"
            >Status :
            <b-badge
              pill
              class="text-capitalize"
              :variant="
                item.status == 'Refund'
                  ? 'warning'
                  : item.status == 'Pending'
                  ? 'light-warning'
                  : 'primary'
              "
            >
              {{ item.status == "Refund" ? item.status + "ed" : item.status }}
            </b-badge>
          </span>
        </p>
      </app-timeline-item>
    </app-timeline>
    <MakePayment :paymentInfo="paymentInfo" />
  </b-card>
</template>

<script>
import { BCard, BImg, BMedia, BAvatar } from "bootstrap-vue";
import AppTimeline from "@core/components/app-timeline/AppTimeline.vue";
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue";
import Ripple from "vue-ripple-directive";
import axios from "axios";
import Swal from "sweetalert2";
import Form from "vform";
import MakePayment from "../../Billing/MakePayment.vue";
export default {
  data() {
    return {
      form: new Form({
        id: "",
        user_id: "",
        amount: "",
        received_amount: 0,
        paid_by: "cash",
        transection_id: "",
      }),
      paymentInfo: {
        modal_title: "null",
        id: null,
        user_id: null,
        amount: null,
        received_amount: null,
      },
    };
  },
  methods: {
    makePayment(id) {
      // console.log(id);
      // this.editData.mode = true;
      let data = this.userData.invoice.find((x) => x.id === id);
      if (data) {
        this.paymentInfo = {
          modal_title: "Make Payment",
          id: data.id,
          user_id: data.user_id,
          amount: data.amount,
          received_amount: data.received_amount,
        };
        this.$store.dispatch("getUsers");
        this.$root.$emit("bv::show::modal", "modal-lg");
      }
    },
    // refund option
    async refund(id) {
      this.$store.dispatch("spinnerLoading", false);
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to refund this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#70e111",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      }).then((result) => {
        if (result.isConfirmed) {
          axios.get(`invoice/refund/${id}`).then((resp) => {
            this.$store.dispatch("spinnerLoading", false);
            if (resp.data.success) {
              this.$s_tost(resp.data.message);
              window.location.reload();
            } else {
              this.$error_message(resp.data.message, resp.data.errors);
            }
          });
        }
      });
      // const deldata = await this.$d_conf(`invoice/refund/${id}`)
      // console.log(deldata);
    },
  },

  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  directives: {
    Ripple,
  },
  components: {
    BCard,
    BImg,
    BMedia,
    BAvatar,
    AppTimeline,
    AppTimelineItem,
    MakePayment,
  },
};
</script>

<style></style>
