<template>
  <b-card  class="p-0">
    <b-row>
      <countdown :target-date="formt_date(userData.connection_info.expire_date)" />
    </b-row>
  </b-card>
</template>
<script>
import countdown from "../countdown.vue"
import { BCard, BButton, BAvatar, BRow, BCol, BBadge } from "bootstrap-vue";
// import useUsersList from "../users-list/useUsersList";

export default {
  name: "UserCountdownCard",
  components: {
    BCard,
    BButton,
    BRow,
    BCol,
    BAvatar,
    BBadge,
    countdown,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  methods: {
    formt_date(val) {
      const dateObj = new Date(val);
      const date = dateObj.toISOString();
      return new Date(date)
    }
  },
  setup() {
    // const { resolveUserRoleVariant } = useUsersList();
    return {
    };
  },
  method: {},
};
</script>

<style>
.bage_083dff {
  background: #083dff;
}
</style>
