<template>
  <b-card>
    <b-row>
      <!-- User Info: Left col -->
      <b-col cols="21" xl="5" class="d-flex justify-content-between flex-column">
        <!-- User Avatar & Action Buttons -->
        <div class="d-flex justify-content-start">
          <b-avatar :src="userData.avatar" size="104px" rounded />
          <div class="d-flex flex-column ml-1">
            <div class="mb-1">
              <h4 class="mb-0">
                {{ userData.name }}
              </h4>
              <span class="card-text">{{ userData.phone }}</span>
            </div>
            <div class="d-flex flex-wrap">
              <b-button :to="{ name: 'apps-users-edit', params: { id: userData.id } }" variant="primary" size="sm">
                Edit
              </b-button>
              <b-button size="sm" variant="outline-danger" class="ml-1">
                Delete
              </b-button>
            </div>
          </div>
        </div>
        <div class="d-flex align-items-center">
          <div class="d-flex align-items-center mr-2">
            <b-avatar variant="light-primary" rounded>
              <feather-icon icon="DollarSignIcon" size="18" />
            </b-avatar>
            <div class="ml-1">
              <small>Balance</small>
              <h5 class="mb-0">
                {{
                  userData.billing_info.balance
                  ? userData.billing_info.balance
                  : 0
                }}
              </h5>
            </div>
          </div>

          <div class="d-flex align-items-center">
            <b-avatar variant="light-success" rounded>
              <feather-icon icon="FlagIcon" size="18" />
            </b-avatar>
            <div class="ml-1">
              <span class="font-weight-bold">Billing Status</span>
              <b-badge pill :variant="
                userData.billing_info && userData.billing_info.status == 0
                  ? 'warning'
                  : 'success'
              " class="text-capitalize">
                {{
                  userData.billing_info && userData.billing_info.status == 0
                  ? "Pending"
                  : "success"
                }}
              </b-badge>
            </div>
          </div>
        </div>
      </b-col>
      <!-- Right Col: Table -->
      <b-col cols="12" xl="7">
        <table class="mt-2 mt-xl-0 w-100">
          <tr>
            <th class="pb-50">
              <feather-icon icon="UserIcon" class="mr-75" />
              <span class="font-weight-bold">Username</span>
            </th>
            <td class="pb-50">
              {{ userData.connection_info.username }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon icon="EyeOffIcon" class="mr-75" />
              <span class="font-weight-bold">Password</span>
            </th>
            <td class="pb-50">
              {{ userData.connection_info.user_password }}
            </td>
          </tr>
          <tr v-if="userData.connection_info.nas_id">
            <th>
              <feather-icon icon="ServerIcon" class="mr-75" />
              <span class="font-weight-bold">Nas</span>
            </th>
            <td>
              {{
                userData.connection_info
                ? `${userData.connection_info.nas.shortname} | ${userData.connection_info.nas.nasname}`
                : "N/A"
              }}
            </td>
          </tr>
          <tr v-if="userData.connection_info.mikrotik_id">
            <th>
              <feather-icon icon="ServerIcon" class="mr-75" />
              <span class="font-weight-bold">MikroTik</span>
            </th>
            <td>
              {{
                userData.connection_info
                ? `${userData.connection_info.mikrotik.identity} | ${userData.connection_info.mikrotik.host}`
                : "N/A"
              }}
            </td>
          </tr>
          <tr v-if="userData.connection_info.mikrotik_id">
            <th class="pb-50">
              <feather-icon icon="CheckIcon" class="mr-75" />
              <span class="font-weight-bold">MikroTik Status</span>
            </th>
            <td class="pb-50 text-capitalize">
              <b-badge @click.prevent="enabledUser(userData)" pill :variant="
                userData.connection_info &&
                  userData.connection_info.status == 1
                  ? 'success'
                  : 'warning'
              " class="text-capitalize">
                {{
                  userData.connection_info &&
                  userData.connection_info.status == 1
                  ? "Enabled"
                  : "Disabled"
                }}
              </b-badge>
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon icon="StarIcon" class="mr-75" />
              <span class="font-weight-bold">Mac Address</span>
            </th>
            <td class="pb-50">
              {{
                userData.connection_info
                ? userData.connection_info.mac_address
                : "N/A"
              }}
              <div v-if="
                userData.connection_info.service == 'oVPN' ||
                userData.connection_info.service == 'PPPoE' ||
                userData.connection_info.service == 'PPtP'
              ">
                <div v-if="userData.connection_info.mac_bind_status == 1" class="badge badge-info" @click="
                  mac_bind(
                    'bind',
                    userData.connection_info.username,
                    userData.id,
                    userData.connection_info.mikrotik_id
                  )
                ">
                  Mac Bind
                </div>
                <div v-else class="badge badge-danger" @click="
                  mac_bind(
                    'Un Bind',
                    userData.connection_info.username,
                    userData.id,
                    userData.connection_info.mikrotik_id
                  )
                ">
                  Mac UnBind
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon icon="CalendarIcon" class="mr-75" />
              <span class="font-weight-bold">Expire Date</span>
            </th>
            <td class="pb-50">
              <b-badge variant="light-success">
                {{ userData.connection_info.expire_date }}
              </b-badge>
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon icon="CircleIcon" class="mr-75" />
              <span class="font-weight-bold">Assigned Static IP</span>
            </th>
            <td class="pb-50">
              <b-badge variant="light-success">
                {{ userData.connection_info.remote_address }}
              </b-badge>
            </td>
          </tr>
        </table>
      </b-col>
    </b-row>
  </b-card>
</template>
<script>
import { statusAction } from "@/utils/helpers/functions";
import axios from "axios";
import Swal from "sweetalert2";
import { BCard, BButton, BAvatar, BRow, BCol, BBadge } from "bootstrap-vue";
// import useUsersList from "../users-list/useUsersList";

export default {
  components: {
    BCard,
    BButton,
    BRow,
    BCol,
    BAvatar,
    BBadge,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  setup() {
    return {
      // mac_bind
      async mac_bind(action, username, itemid = null, mikrotik_id = null) {
        let route = `mac-bind?itemid=${itemid}&mikrotik=${mikrotik_id}&user_name=${username}&action=${action}`;
        await Swal.fire({
          title: "Are you sure?",
          text: `You Want to ${action} It`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#70e111",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes!",
        }).then((result) => {
          if (result.isConfirmed) {
            this.axios.post(route).then((resp) => {
              if (resp.data.success) {
                this.userData.connection_info.mac_bind_status = resp.data.data.status
                this.userData.connection_info.mac_address = resp.data.data.mac_address
                this.$s_tost(resp.data.message);
              } else {
                this.$error_message(resp.data.messadge, resp.data.errors);
              }
            });
          }
        });
      },
      async enabledUser(data) {
        let route = `mikrotikuserstatus?itemid=${data.id}&mikrotik=${data.connection_info.mikrotik_id}&user_name=${data.connection_info.username}`;
        await Swal.fire({
          title: "Are you sure?",
          text: `You won't to Change It`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#70e111",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes!",
        }).then((result) => {
          if (result.isConfirmed) {
            this.axios.post(route).then((resp) => {
              if (resp.data.success) {
                this.$success_message(resp.data.message);
                this.getData();
              } else {
                this.$error_message(resp.data.message, resp.data.errors);
              }
            });
          }
        });
      },


    };
  },
  method: {},
};
</script>

<style>
.bage_083dff {
  background: #083dff;
}
</style>
