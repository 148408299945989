<template>
  <b-card no-body class="border-primary">
    <b-card-header
      class="justify-content-between align-items-center pt-75 pb-25 p-1"
    >
      <h5 title="" class="mb-0 text-center w-100">Package Information</h5>
      <h6 title="" class="mb-0">{{ userData.billing_info.package.name }}</h6>
      <b-badge variant="light-primary">
        {{ userData.billing_info.package.price }}TK</b-badge
      >
      <b-button
        @click.prevent="
          ChangeUserPcakage(
            userData.connection_info.mikrotik_id,
            userData.connection_info.user_id,
            userData.billing_info.package_id
          )
        "
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        size="sm"
        block
      >
        Change Package
      </b-button>
    </b-card-header>

    <b-card-body class="p-1">
      <ul class="list-unstyled my-1">
        <li>
          <span class="align-middle">OLT:</span>
        </li>
        <li class="my-25">
          <span class="align-middle">Design2</span>
        </li>
        <li class="my-25">
          <span class="align-middle">Design2</span>
        </li>
        <li>
          <span class="align-middle">Basic Support</span>
        </li>
      </ul>
    </b-card-body>

    <b-modal id="update_package" size="lg" title="" hide-footer>
      <div class="d-flex mb-2">
        <b-form-radio
          v-model="form.expire_date_type"
          class="mr-2"
          name="some-radios"
          value="current"
        >
          Use Current Expire Date
        </b-form-radio>
        <b-form-radio
          v-model="form.expire_date_type"
          name="some-radios"
          value="custom"
        >
          Use Custom Expire Date
        </b-form-radio>
      </div>
      <div class="row">
        <div class="col-7">
          <div class="badge badge-light-primary text-right">
            Current Pack: {{ this.current_package_info.name }}
            {{
              form.expire_date_type == "custom"
                ? formatDateTime(form.expire_date)
                : form.expire_date
            }}
          </div>
          <div class="badge badge-light-success text-right">
            New Pack: {{ this.new_package_info.name }}
          </div>
          <form action="" @submit.prevent="change_package()">
            <div v-if="form.expire_date_type == 'custom'" class="mb-2">
              <label for="">Custom Expire Date</label>
              <input
                @input="change_invoice_cal"
                v-model="form.expire_date"
                type="datetime-local"
                class="form-control"
              />
            </div>
            <div class="mb-2">
              <label for="">Discount</label>
              <div class="d-flex">
                <input
                  @input="change_invoice_cal"
                  v-model="form.discount"
                  type="number"
                  min="0"
                  class="form-control"
                />
                <button
                  v-b-tooltip.top.v-danger
                  title="Make sure youn have to change?"
                  class="btn btn-primary btn-sm"
                >
                  <feather-icon
                    :icon="!edit_ref ? 'Edit3Icon' : 'ArrowRightIcon'"
                    size="16"
                  />
                </button>
              </div>
            </div>
            <button
              @click.prevent="change_package()"
              class="btn btn-success btn-sm"
            >
              Save
            </button>
          </form>
        </div>
        <div class="col-5 border card-body p-0 py-1 px-1">
          <h6 class="text-center" for="">Invoice</h6>
          <tr v-if="form.monthly_bill">
            <td class="w-50">Package Price</td>
            <td>:</td>
            <td class="w-25">{{ parseFloat(form.monthly_bill).toFixed(2) }}</td>
          </tr>
          <tr v-if="form.balance">
            <td class="w-50">Current Balance</td>
            <td>:</td>
            <td :class="[form.balance < 0 ? 'text-danger w-50' : 'w-50']">
              {{ form.balance }}
            </td>
          </tr>

          <tr>
            <td colspan="5" class="saperator_border"></td>
          </tr>
          <tr>
            <td class="font-weight-bold">Payable Bill</td>
            <td class="font-weight-bold">:</td>
            <td class="font-weight-bold">
              {{ parseFloat(form.total_invoice_price).toFixed(2) }}
            </td>
          </tr>
          <tr v-if="form.discount">
            <td>Discount</td>
            <td>:</td>
            <td>{{ parseFloat(form.discount).toFixed(2) }}</td>
          </tr>
          <tr>
            <td colspan="5" class="saperator_border"></td>
          </tr>
          <tr>
            <td>Total</td>
            <td>:</td>
            <td>{{ parseFloat(form.total_amount).toFixed(2) }}</td>
          </tr>
        </div>
      </div>
    </b-modal>
  </b-card>
</template>

<script>
import { BCard, BCardHeader, BCardBody, BBadge, BButton } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import Swal from "sweetalert2";
import Form from "vform";
import axios from "axios";

export default {
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      form: new Form({
        user_id: null,
        mikrotik_id: null,
        expire_date_type: "current",
        expire_date: "",
        monthly_bill: "",
        new_package_id: "",
        old_package_id: "",
        discount: "",
        balance: "",
        total_invoice_price: "",
        total_amount: "",
      }),
      current_package_info: {},
      new_package_info: {},
      user_bill_info: {},
      user_connection_info: {},
      edit_ref: false,
    };
  },
  methods: {
    //change mikrotik package
    ChangeUserPcakage(mikrotik_id, user_id, package_id) {
      this.axios
        .get(`mikrotik-package?mikrotik_id=${mikrotik_id}`)
        .then((res) => {
          const options = {};
          res.data.data.forEach((element) => {
            options[element.id] =
              element.name +
              " || (" +
              element.mikrotik.identity +
              " || " +
              element.mikrotik.host +
              ")";
          });
          Swal.fire({
            title: "Please Select Package",
            input: "select",
            inputOptions: options,
            inputPlaceholder: "Plsase Select One",
            showCancelButton: true,
          }).then((result) => {
            if (result.isConfirmed) {
              this.$bvModal.show("update_package");
              this.axios
                .post(`change-user-package?type=1`, {
                  user_id: user_id,
                  current_package_id: package_id,
                  new_package_id: result.value,
                })
                .then((resp) => {
                  this.form.new_package_id = result.value;
                  this.form.old_package_id = package_id;
                  this.form.user_id = user_id;
                  this.form.mikrotik_id = mikrotik_id;
                  this.current_package_info = resp.data.data.current_package;
                  this.new_package_info = resp.data.data.new_package;
                  this.user_bill_info = resp.data.data.user_bill_info;
                  this.user_connection_info =
                    resp.data.data.user_connection_info;
                  this.form.balance = resp.data.data.user_bill_info.balance;
                  this.form.expire_date =
                    resp.data.data.user_connection_info.expire_date;
                  this.form.monthly_bill = resp.data.data.new_package.price;
                  this.change_invoice_cal();
                });
            }
          });
        });
    },
    // change_invoice_cal
    change_invoice_cal() {
      const balance = parseInt(this.form.balance ? this.form.balance : 0);
      const monthly_bill = parseInt(
        this.form.monthly_bill ? this.form.monthly_bill : 0
      );
      const discount = parseInt(this.form.discount ? this.form.discount : 0);
      this.form.total_invoice_price = monthly_bill - balance;
      if (discount >= 0 && this.form.total_invoice_price >= discount) {
        this.form.total_amount = this.form.total_invoice_price - discount;
      } else {
        this.form.discount = this.form.total_invoice_price;
        this.form.total_amount =
          this.form.total_invoice_price - this.form.discount;
        this.$error_message(
          "Discount Balance can not be greater then due amount"
        );
      }
    },
    // change_package
    change_package() {
      this.axios.post(`change-user-package?type=2`, this.form).then((resp) => {
        if (resp.data.success) {
          this.$s_tost(resp.data.message);
          this.$bvModal.hide("update_package");
          window.location.reload();
        } else {
          this.$error_message("Wrong", resp.data.message);
        }
      });
    },
  },

  directives: {
    Ripple,
  },
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BBadge,
    BButton,
  },
};
</script>

<style></style>
